import { Directive, TemplateRef, Input, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[isFeatureEnabled]'
})
export class FeatureFlagDirective {
    @Input()
    set isFeatureEnabled(isEnabled: string) {
        if (!isEnabled) {
            this.viewContainerRef.clear();
            return;
        }
        this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
    constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) { }
}