import { NgModule } from '@angular/core';
import { OpenAiChatBotComponent } from './opeanai.chatbot.component';
import { RenderMarkDownComponent } from './render.markdown.text';
import { OpenAiChatAssistantMessageComponent } from './openai.assistant.message';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TypingAnimationModule } from '../shared/typing-animation/typing.animation.module';
@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        TypingAnimationModule
    ],
    declarations: [
        OpenAiChatBotComponent,
        OpenAiChatAssistantMessageComponent,
        RenderMarkDownComponent
    ],
    exports: [
        OpenAiChatBotComponent
    ]
})

export class OpenAiChatBotModule { }