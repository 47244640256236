<app-reseller-header *ngIf="currentUser && currentUser.role.id === 6"></app-reseller-header>
<app-header *ngIf="currentUser && currentUser.role.id !== 6" (toggleOpenAIPane)="onToggleOpenAIPane($event)"
  [openai_pane_closed]="!openai_pane_open"></app-header>
<mat-sidenav-container *restrictAccess="['super admin', 'admin', 'user', 'member']" class="main"
  (backdropClick)="close()">
  <mat-sidenav *ngIf="!isAccountDeactivated" #sidenav mode="side" opened="true" disableClose
    (mouseenter)="showSidebar()" (mouseleave)="hideSidebar()" (keydown.escape)="hideSidebar()">
    <mat-nav-list>
      <mat-list-item routerLink="/" role="list" style="font-weight: 500;color:red">
        <mat-icon matListItemIcon fontIcon="signal_cellular_alt"></mat-icon>
        <span *ngIf="isExpanded" mat-line>Dashboard</span>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user']" routerLink="/programs" role="list">
        <mat-icon matListItemIcon [matBadge]="helper_menu_number" matBadgeSize="small" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'programs'"
          aria-hidden="false">check
        </mat-icon>
        <mat-icon matListItemIcon *ngIf="current_tip_target !== 'programs'">check</mat-icon>
        <span *ngIf="isExpanded" mat-line>Programs</span>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages:['enterprise', 'pro']"
        routerLink="/incidents-risks">
        <mat-icon matListItemIcon [matBadge]="helper_menu_number" matBadgeSize="small" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'incidents-risks'"
          aria-hidden="false">speed
        </mat-icon>
        <mat-icon matListItemIcon *ngIf="current_tip_target !== 'incidents-risks'">speed</mat-icon>
        <span *ngIf="isExpanded" mat-line>Incident/Risk Tracker</span>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages:['enterprise', 'pro']"
        routerLink="/documents">
        <mat-icon matListItemIcon [matBadge]="helper_menu_number" matBadgeSize="small" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'documents'"
          aria-hidden="false">policy
        </mat-icon>
        <mat-icon matListItemIcon *ngIf="current_tip_target !== 'documents'">policy</mat-icon>
        <span *ngIf="isExpanded" mat-line>Documents</span>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages:['enterprise']"
        routerLink="/programs/controls-manager">
        <mat-icon matListItemIcon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'controls_manager'"
          aria-hidden="false">
          backup_table
        </mat-icon>
        <mat-icon matListItemIcon *ngIf="current_tip_target !== 'controls_manager'">backup_table</mat-icon>
        <span *ngIf="isExpanded" mat-line>Controls Manager</span>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages:['enterprise']" routerLink="/vendors">
        <mat-icon matListItemIcon [matBadge]="helper_menu_number" matBadgeSize="small" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'vendors'"
          aria-hidden="false">assignment
        </mat-icon>
        <mat-icon matListItemIcon *ngIf="current_tip_target !== 'vendors'">assignment</mat-icon>
        <span *ngIf="isExpanded" mat-line>Vendors</span>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages: ['enterprise', 'pro', 'free']"
        routerLink="/risk-score">
        <mat-icon matListItemIcon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'risk-score'"
          aria-hidden="false">
          score
        </mat-icon>
        <mat-icon *ngIf="current_tip_target !== 'risk-score'" matListItemIcon>score</mat-icon>
        <span *ngIf="isExpanded" mat-line>Risk Score</span>
      </mat-list-item>
      <mat-list-item
        *restrictAccess="['super admin', 'admin', 'user', 'reseller'];packages: ['enterprise', 'pro', 'free'];auxilliaryPackages:['Threat Module']"
        routerLink="/threats">
        <mat-icon matListItemIcon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'threats'"
          aria-hidden="false">
          shield
        </mat-icon>
        <mat-icon matListItemIcon *ngIf="current_tip_target !== 'threats'">shield</mat-icon>
        <span *ngIf="isExpanded" mat-line>Threat Module</span>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin']" routerLink="/programs/master-controls">
        <mat-icon matListItemIcon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'master_controls'"
          aria-hidden="false">
          source
        </mat-icon>
        <mat-icon matListItemIcon *ngIf="current_tip_target !== 'master_controls'">source</mat-icon>
        <span *ngIf="isExpanded" mat-line>Master Control List</span>
      </mat-list-item>
      <mat-list-item *restrictAccess="['super admin']" routerLink="/customer-support">
        <mat-icon matListItemIcon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'customer-support'"
          aria-hidden="false">
          help-center
        </mat-icon>
        <mat-icon matListItemIcon *ngIf="current_tip_target !== 'customer-support'">help_center</mat-icon>
        <span *ngIf="isExpanded" mat-line>Customer Support</span>
      </mat-list-item>
      <ng-template [ngIf]="assessments > 0">
        <mat-list-item
          *restrictAccess="['super admin', 'admin', 'user', 'member'];packages:['enterprise', 'pro', 'free']"
          routerLink="/assessments">
          <mat-icon matListItemIcon>assessment</mat-icon>
          <span *ngIf="isExpanded" mat-line>Vendor Assessments</span>
        </mat-list-item>
      </ng-template>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <div class="main-content-container">
      <div class="routeroutlet">
        <div *ngIf="httpProgressBarService.isLoading | async">
          <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
        </div>
        <router-outlet></router-outlet>
      </div>
      <div class="ai-chat-assistant" *ngIf="openai_pane_open">
        <mat-card style="margin:20px">
          <div style="display: flex;justify-content: flex-end;">
            <button mat-icon-button aria-label="Close Co-pilot chat" (click)="onToggleOpenAIPane(false)">
              <mat-icon style="align-self:flex-end">close</mat-icon>
            </button>
          </div>
          <app-openai-chat-component></app-openai-chat-component>
        </mat-card>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div *restrictAccess="['vendor']">
  <div *ngIf="httpProgressBarService.isLoading | async">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>
  <router-outlet></router-outlet>
</div>

<div *restrictAccess="['reseller']">
  <div *ngIf="httpProgressBarService.isLoading | async">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>
  <router-outlet></router-outlet>
</div>