import { Component, OnDestroy } from '@angular/core';
import { OpenAiChatComponentService } from './opeanai.chatbot.component.service';
import { Subscription } from 'rxjs';
import { marked } from 'marked';
import { HttpErrorResponse } from '@angular/common/http';

export const RESP = [
    {
        "index": 0,
        "message": {
            "role": "assistant",
            "content": "Here are three controls owned by CKW for customer ID `ee063a6c-95cc-4bd8-ac13-f66b05b0f3c3` and user ID `8502f273-36f4-4cd0-9be0-adc2b7235739`:\n\n1. **Control ID**: 9ac517ca-c8d0-4cc0-a24c-6a9ab76dfcef\n   - **Wording**: Test wording\n   - **Status ID**: 2\n\n2. **Control ID**: 40bf746b-86ca-454c-9154-76efcace46ea\n   - **Wording**: Test control ownership\n   - **Status ID**: 1\n\n3. **Control ID**: aab37cb6-72fe-4164-86dc-db0ac27a49c4\n   - **Wording**: Test control Wording\n   - **Status ID**: 2 \n\nIf you need further information or more controls, feel free to ask!",
            "refusal": null
        },
        "logprobs": null,
        "finish_reason": "stop"
    }
];

const emailPrompts = [
    { role: 'custom_prompt', content: 'Send email reminder to control owners with overdue controls', prompt: 'Send email reminders to control owners who have overdue controls. Group by email and capture control id, control name,control status,notify_on, domain name and program name for overdue controls' }
]

@Component({
    selector: 'app-openai-chat-component',
    templateUrl: './opeanai.chatbot.component.html',
    styleUrls: ['./opeanai.chatbot.component.css']
})

export class OpenAiChatBotComponent implements OnDestroy {
    userMessage: string = '';
    assistantReply: string;
    chatMessages: { role: string, content: string, prompt?: string }[] = [];
    subscriptions: Subscription[] = [];
    start: boolean = false;
    innerHtml = '';
    optimisticMessages: { message: string, startDelay: number, typeSpeed: number }[] = [{ message: 'Working on your request....', startDelay: 0, typeSpeed: 50 }, { message: 'Hang in there....', startDelay: 5000, typeSpeed: 100 }, { message: 'Finalizing your request....', startDelay: 7000, typeSpeed: 150 }];
    fetching = false;
    searchMaxLength: number = 300;
    constructor(private openaiChatComponentService: OpenAiChatComponentService) {
    }

    async sendMessage() {
        const userMessage = this.userMessage.trim();
        this.userMessage = '';
        this.chatMessages.push({ role: 'user', content: userMessage });
        if (this.determineIfUserIntentIsSendingMail(userMessage)) {
            this.chatMessages.pop();
            emailPrompts.forEach(entry => {
                // this.chatMessages.push({role: entry.role,content: entry.content});
                this.chatMessages.push(entry);
            });
            return;
        }
        // filter out error messages from chat history
        const chatHistory = this.chatMessages.filter(entry => {
            if (entry.role == 'error_message' || entry.role == 'custom_prompt') {
                return false;
            }
            return true;
        }
        );

        /*RESP[0].message.content = await marked.parse(RESP[0].message.content);
        this.chatMessages.push(RESP[0].message);*/
        this.fetching = true;
        // call api
        this.subscriptions.push(
            this.openaiChatComponentService.sendMessage([chatHistory[chatHistory.length - 1]]).subscribe(async (resp) => {
                if (resp) {
                    resp.forEach(async (entry, index) => {
                        if (entry.role === 'assistant' && entry.content) {
                            entry.content = await marked.parse(entry.content);
                        }
                        if (index === resp.length - 1) {
                            // this.chatMessages = resp;
                            this.chatMessages.push(resp[resp.length - 1]);
                        }
                    })
                } else {
                    this.chatMessages.push({ role: 'error_message', content: "AI assistant couldn't answer your query. Try again" });
                }
                this.fetching = false;
            }, async (error: HttpErrorResponse) => {
                const { statusText } = error;
                this.fetching = false;
                if (error.error != ProgressEvent) {
                    this.chatMessages.push({ role: 'error_message', content: statusText });
                } else
                    this.chatMessages.push({ role: 'error_message', content: statusText });
            })
        );
        this.userMessage = '';
    }

    determineIfUserIntentIsSendingMail(userRequest: string) {
        userRequest = userRequest.toLocaleLowerCase();
        const preparedPrompts = emailPrompts.map(entry => (entry.prompt).toLocaleLowerCase());
        if ((userRequest.includes('send email') || userRequest.includes('write email')) && !preparedPrompts.includes(userRequest)) {
            return true;
        }
        return false;
    }

    onCustomPromptClicked(chatMessage) {
        this.userMessage = chatMessage.prompt;
        this.chatMessages.pop();
        this.sendMessage();
    }

    isLastContentFromAssistant(i: number) {
        return i === this.chatMessages.length - 1 ? true : false
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}