import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class OpenAiChatComponentService {
    constructor(private http: HttpClient) {

    }

    sendMessage(message: Array<{ role: string, content: string }>) {
        return this.http.post<any>(`${environment.apiUrl}/openai/chat`, message).pipe(map(result => result));// .pipe(catchError(err => err));
    }
}