import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { AuditService } from '../../core/services/audit.service';
import { UserService } from '../../core/services/user.service';
import { AuditDataSource } from '../../data-sources/audit.data-source';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class AuditComponent implements AfterViewInit, OnInit {
  public dataSource: AuditDataSource;
  public displayedColumns: string[] = ['name', 'date'];
  public expandedElement: any;
  private customerId: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private auditService: AuditService, route: ActivatedRoute, userService: UserService) {
    if (userService.isCurrentUserSuperAdmin) {
      route.params.subscribe((params) => {
        this.customerId = params.id;
      });
    }
  }

  ngOnInit() {
    this.dataSource = new AuditDataSource(this.auditService);
    this.dataSource.load(0, 20, this.customerId);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadMore())).subscribe();
  }

  loadMore() {
    this.dataSource.load(this.paginator.pageIndex, this.paginator.pageSize, this.customerId);
  }
}
