import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserResolver, GetUserResolver } from './users/resolvers/user.resolver';
import { ApplicationComponent } from './components/global/application/application.component';

import { DomainResolver } from './core/resolvers/domain.resolver';
import { UserInvitationComponent } from './components/user-invitations/user-invitation.component';
import { RoleGuard } from './core/helpers/role.guard';
import { PersonalSettingsComponent } from './users/components/settings/personal-settings.component';
import { SignupPageComponent } from './signup/components/signup.component';
import { UserRoles } from './core/helpers/user-roles.enum';
import { AuditComponent } from './components/audit/audit.component';
import { AppGuard } from './auth/guards/app.guard';
import { ResellerApplicationComponent } from './components/global/reseller-application/reseller-application.component';

const routes: Routes = [
  {
    path: 'invite/:token',
    component: UserInvitationComponent
  },
  {
    path: 'signup',
    component: SignupPageComponent,
  },
  {
    path: '',
    canActivate: [AppGuard],
    runGuardsAndResolvers: 'always',
    component: ApplicationComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'programs',
        canActivate: [RoleGuard],
        loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule)
      },
      {
        path: 'controls',
        loadChildren: () => import('./controls/controls.module').then(m => m.ControlsModule)
      },
      {
        path: 'personal-settings',
        component: PersonalSettingsComponent,
      },
      {
        path: 'vendors',
        loadChildren: () => import('./vendors/vendors.module').then(m => m.VendorsModule)
      },
      {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule)
      },
      {
        path: 'incidents-risks',
        canActivate: [RoleGuard],
        loadChildren: () => import('./incidents-risks/incidents-risks.module').then(m => m.IncidentsRisksModule),
      },
      {
        path: 'assessments',
        loadChildren: () => import('./assessments/assessments.module').then(m => m.AssessmentsModule),
        // canActivate: [RoleGuard],
        data: {
          roles: [UserRoles.Vendor],
        }
      },
      {
        path: 'admin',
        children: [
          {
            path: '',
            children: [
              {
                path: '',
                redirectTo: 'users',
                pathMatch: 'full',
              },
              {
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
              },
              {
                path: 'customers',
                loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
              },
              {
                path: 'reseller-companies',
                loadChildren: () => import('./resellers/resellers.module').then(m => m.ResellersModule),
              },
              {
                path: 'audit',
                children: [
                  {
                    path: '',
                    component: AuditComponent,
                    canActivate: [RoleGuard],
                    data: {
                      roles: [
                        UserRoles.SuperAdmin,
                        UserRoles.Admin,
                      ]
                    }
                  },
                  {
                    path: ':id',
                    component: AuditComponent,
                    canActivate: [RoleGuard],
                    data: {
                      roles: [
                        UserRoles.SuperAdmin,
                      ],
                    }
                  }
                ]
              },
              {
                path: 'monitors',
                loadChildren: () => import('./monitors/monitors.module').then(m => m.MonitorsModule)
              }
            ]
          }
        ]
      },
      {
        path: 'risk-score',
        loadChildren: () => import('./risk-score/risk-score.module').then(m => m.RiskScoreModule)
      },
      {
        path: 'threats',
        loadChildren: () => import('./threat-module/threat-module.module').then(m => m.ThreatModule)
      },
      {
        path: 'customer-support',
        loadChildren: () => import('./customer-support/customer-support.module').then(m => m.CustomerSupportModule)
      }
    ]
  },
  {
    path: 'reseller',
    canActivate: [AppGuard],
    runGuardsAndResolvers: 'always',
    component: ResellerApplicationComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./reseller-dashboard/reseller_dashboard.module').then(m => m.ResellerDashboardModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
})
  ],
  exports: [
    RouterModule
  ],
  providers: [
    UserResolver,
    GetUserResolver,
    DomainResolver,
  ],
})
export class AppRoutingModule { }
