import { Component, Input } from "@angular/core";
@Component({
    selector: 'app-openai-chat-assistant-message',
    template: `
        <span [innerHTML] = "chatMessage" *ngIf = "!humanTyping"></span>
        <span appTypingAnimation [typeSpeed]="0.5" [startDelay]="10" condition="true" [text] = "chatMessage" parseMardkown ="true" (typingComplete) = "onDoneTyping()" *ngIf = "humanTyping"></span>
    `
})

export class OpenAiChatAssistantMessageComponent {
    @Input() chatMessage: string;
    humanTyping: boolean = true;
    constructor() { }

    onDoneTyping() {
        this.humanTyping = false;
    }
}