import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryWhen, scan, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';

export const retryCount = 3;

@Injectable()
export class HttpRetryInterceptorService implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            retryWhen(errors => errors.pipe(
                scan((retryCount, error) => {
                    if (error.status === 401) {
                        return this.authService.doLogoutAndRedirectToLogin();
                    }
                    if (retryCount < 3 && error.status >= 400) { // Retry only on server errors
                        // skip retrying openai errors
                        if (req.url.includes('/openai/chat')) {
                            throw error;
                        }
                        return retryCount + 1;
                    }
                    else if (retryCount < 3 && error.status === 0) {
                        return retryCount + 1;
                    }
                    else {
                        throw error;
                    }
                }, 0),
                delay(2000) // Delay between retries
            ))
        );
    }
}