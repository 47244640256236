import { createReducer, on } from '@ngrx/store';
import { ProgramFilters } from '../../core/models/ProgramFilters';
import { setProgramFilters, clearProgramFilters } from '../Actions/programs.filter.action';

const initialState: Readonly<ProgramFilters> = localStorage.getItem('programFilters') ?
 JSON.parse(localStorage.getItem('programFilters')) : { search: '', status: '', frequency: '', owner: '' };

/* eslint-disable @typescript-eslint/no-unused-vars */
export const programFiltersReducer = createReducer(
    initialState,
    on(setProgramFilters, (state, currentFilter: ProgramFilters) => currentFilter),
    on(clearProgramFilters, (state) => initialState)
);
/* eslint-enable @typescript-eslint/no-unused-vars */
