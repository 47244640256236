import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { User } from '../../../core/models/User';
import { UserService } from '../../../core/services/user.service';
import { Observable, Subscription } from 'rxjs';
import { Customer } from '../../../core/models/Customer';
import { ControlsService } from 'src/app/controls/controls.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Output() toggleOpenAIPane: EventEmitter<boolean> = new EventEmitter();
  @Input() openai_pane_closed: boolean = false;
  public currentUser: User;
  public customerId: string;
  public notificationsCount: Observable<number>;
  public customers: Customer[] = [];
  subscription: Subscription;
  subscriptions: Subscription[] = [];
  public isHelperMenusPresent = false;
  is_openai_pane_open = false;
  opseaseAIEnabled: boolean;
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private controlsService: ControlsService,
  ) {
    this.fetchUpdatedUser();
    this.fetchPendingNotifications();
  }

  fetchUpdatedUser() {
    this.subscriptions.push(
      this.userService.updatedUser
        .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.customerId = this.currentUser && this.currentUser.customer ? this.currentUser.customer.id : null;
          }
        }
        )
    );
  }

  fetchPendingNotifications() {
    this.subscriptions.push(
      this.controlsService.index(0, 20, { filter: 'pending' }).subscribe(resp => {
        if (resp.total) {
          this.controlsService.setDueControls(resp.total);
          this.notificationsCount = this.controlsService.getDueControls();
          // sessionStorage.setItem('notificationsCount', resp.total);
        }
      })
    );
  }

  resetNotificationsCount() {
    this.controlsService.setDueControls('');
    this.notificationsCount = this.controlsService.getDueControls();
  }

  isHelperMenuPresent() {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    const helperMenus = userDetails.expectedHelperMenus;
    if (!helperMenus) {
      this.isHelperMenusPresent = false;
    } else if (!helperMenus.length) {
      this.isHelperMenusPresent = false;
    } else {
      this.isHelperMenusPresent = true;
    }
  }

  getHelperMenus() {
    this.subscriptions.push(
      this.userService.fetchHelperMenus().subscribe(resp => {
        this.userService.setHelperMenus(resp);
      })
    );
  }

  toggleOpenAIAssistant() {
    this.is_openai_pane_open = this.is_openai_pane_open ? false : true;
    this.toggleOpenAIPane.emit(this.is_openai_pane_open);
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.authService
      .logout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/login']).then(() => {
          location.reload();
        });
      });
  }
  ngOnInit() {
    this.currentUser = this.userService.currentUserValue;
    this.customerId = this.currentUser && this.currentUser.customer ? this.currentUser.customer.id : null;
    this.opseaseAIEnabled = this.activatedRoute.snapshot.queryParams['opseaseai'] === 'true' ? true : false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    const pane_closed: boolean = (changes['openai_pane_closed'].currentValue) as boolean;
    if (pane_closed)
      this.is_openai_pane_open = !pane_closed;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
