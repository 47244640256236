import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/User';
import { Customer } from '../models/Customer';

@Injectable()
export class CustomersInterceptor implements HttpInterceptor {

  constructor() {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reseller: User = JSON.parse(localStorage.getItem('user'));
    const customerOwner: Customer | null = JSON.parse(sessionStorage.getItem('customerObject'));

    if (reseller && reseller.role && reseller.role.id === 6 && customerOwner) {
      const modifiedReq = request.clone({
        headers: request.headers.set('reseller-customer', customerOwner.owner_id),
      });
      return next.handle(modifiedReq);
    } else {
      return next.handle(request);
    }
  }
}
