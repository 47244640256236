import { Directive, OnInit, OnChanges, ElementRef, Input, Output, EventEmitter, SimpleChanges, AfterViewInit } from '@angular/core';
import { Typed } from './typed';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs'

@Directive({
    selector: '[appTypingAnimation]'
})
export class TypingAnimationDirective implements OnInit, OnChanges, AfterViewInit {
    typed: Typed;
    @Input() text: any = '';
    @Input() typeSpeed: number = 0;
    @Input() startDelay: number = 0;
    @Input() condition: boolean = true;
    @Input() parseMardkown: boolean = false;
    @Input() hideCursorOnComplete: boolean = true;
    @Output() typingComplete: EventEmitter<null> = new EventEmitter();
    typingLock: boolean = false;
    contentObservable: Observable<string>;
    contentSubscription: Subscription;

    constructor(private elRef: ElementRef) {
    }

    ngOnInit(): void {
        if (!this.checkContent()) {
            return;
        }

        this.createTyped();
    }

    ngAfterViewInit(): void {
        if (this.typed) {
            return;
        }
        if (!this.checkContent()) {
            this.contentObservable = new Observable((ob) => {
                if (this.checkContent()) {
                    ob.next(this.text);
                }
            });

            this.contentSubscription = this.contentObservable.subscribe(() => {
                this.createTyped();
                this.contentSubscription.unsubscribe();
            });

            return;
        }
        this.createTyped();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (('condition' in changes) && this.typed) {
            if (this.typingLock) {
                return;
            }

            if (this.condition) {
                this.typed.begin();
                this.typingLock = true;
            }
        }

        if ('text' in changes && this.typed) {
            if (this.typingLock) {
                return;
            }
            if (this.condition) {
                this.typed.textContent = this.text;
                this.typed.begin();
                this.typingLock = true;
            }
        }
    }

    private checkContent() {
        return this.text;
    }

    private createTyped() {
        this.typed = new Typed(
            this.elRef.nativeElement, {
            typeSpeed: this.typeSpeed,
            startDelay: this.startDelay,
            condition: this.condition,
            parseMardkown: this.parseMardkown,
            hideCursorOnComplete: this.hideCursorOnComplete,
            onComplete: () => {
                this.typingComplete.emit(null);
                this.typingLock = false;
            }
        },
            this.text
        );
        if (this.condition) {
            this.typed.begin();
            this.typingLock = true;
        }
    }


}