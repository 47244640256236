<div class="app-signup-page-container">
  <section class="section_left">
    <div class="w-full no-programs text-center">
      <a href="https://opsease.com"><img
        class="transparent-logo light-scheme-logo"
        src="https://opsease.com/wp-content/uploads/2020/12/OpsEase_Logo_Color_White.svg"
        alt="OpsEase"
      /></a>
      <h2>Sign Up</h2>
      <div class="background_image"></div>
      <div class="footer_note">Copyright &#64; OpsEase 2021. All Rights Reserved.</div>
    </div>
  </section>

  <section class="section_right">
    <mat-card class="signup-box">
      <h1>Create OpsEase Account</h1>

      <mat-spinner mode="indeterminate" class="loadingSpinner" *ngIf="showLoading" diameter="40"></mat-spinner>
      <div class="response_message" *ngIf="showResponseMessage"><b class="errMsg">Failed: <br/><br/></b>{{ errorMessage }}<br/><a href="javascript:void(0);" (click)="tryAgain()">Try Again</a><br/></div>
      <div class="success_message" *ngIf="showSuccessMessage && accountType !== '1'" ><b class="sccMsg">Success!</b> <br/><br/>Your OpsEase account was created,<br/>proceed to <a href="/login">Log In</a><br/><br/></div>
      <div class="success_message" *ngIf="showSuccessMessage && accountType === '1'"><b class="sccMsg">Success!</b> <br/><br/>Your OpsEase account was created.<br/>Activate your account by clicking the link <br/> sent to your email</div>
      <form class="login-form" *ngIf="showForms" [formGroup]="signUpForm" (ngSubmit)="signUp()">
        <mat-error class="login-error" *ngIf="signupError">{{ signupError }}</mat-error>

        <h3>Account Information</h3>

        <mat-form-field appearance="fill" class="accountTypeSelector">
          <mat-label>Account Type</mat-label>
          <mat-select [(value)]="accountType" (selectionChange)="getCountriesData()">
            <mat-option value="1">Free</mat-option>
            <mat-option value="2">Pro - $140</mat-option>
            <mat-option value="3">Enterprise - $280</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="fname_box">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="first_name" required />
          <mat-error
            *ngIf="first_name.invalid && (first_name.dirty || first_name.touched) && first_name.errors.required"
          >
            First Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="lname_box">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="last_name" required />
          <mat-error *ngIf="last_name.invalid && (last_name.dirty || last_name.touched) && last_name.errors.required">
            Last Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="email_box">
          <mat-label>Email Address</mat-label>
          <input matInput name="email" formControlName="email" required />
          <mat-error *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required">
            Email is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customer_name_box">
          <mat-label>Customer Name</mat-label>
          <input matInput type="text" formControlName="customer_name" required />
          <mat-error
            *ngIf="
              customer_name.invalid && (customer_name.dirty || customer_name.touched) && customer_name.errors.required
            "
          >
            Customer Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pass_box">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" required />
          <mat-error *ngIf="password.invalid && (password.dirty || password.touched) && password.errors.required">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pass_confirm_box">
          <mat-label>Confirm Password</mat-label>
          <input matInput type="password" formControlName="confirm_password" required />
          <mat-error
            *ngIf="
              confirm_password.invalid &&
              (confirm_password.dirty || confirm_password.touched) &&
              confirm_password.errors.required
            "
          >
            Password confirmation is <strong>required</strong>
          </mat-error>

        </mat-form-field>

        <div class="mee" *ngIf="accountType !== '1'">
          <h3>Billing Information</h3>
          <mat-form-field appearance="outline" class="country_box">
            <mat-label>Country</mat-label>
            <mat-select
              [formControl]="billing_country"
              (selectionChange)="loadStatesForCountry($event)"
              placeholder="Country"
            >
              <mat-option *ngFor="let obj of country_list" value="{{ obj.isoCode }}">{{ obj.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!billing_country.valid">
              <strong>Country is required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="state_box">
            <mat-label>State</mat-label>
            <mat-select [formControl]="billing_state" (selectionChange)="loadCitiesForState($event)">
              <mat-option *ngFor="let obj of list_of_states" value="{{ obj.isoCode }}">{{ obj.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!billing_state.valid">
              <strong>State is required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="city_box">
            <mat-label>City</mat-label>
            <mat-select [formControl]="billing_city" (selectionChange)="setCity($event)">
              <mat-option *ngFor="let obj of list_of_cities" value="{{ obj.name }}">{{ obj.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!billing_city.valid">
              <strong>City is required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="bill_address_line1">
            <mat-label>Billing Address</mat-label>
            <input matInput type="text" formControlName="billing_address_line1" required />
            <mat-error
              *ngIf="
                billing_address_line1.invalid &&
                (billing_address_line1.dirty || billing_address_line1.touched) &&
                billing_address_line1.errors.required
              "
            >
              Billing Address is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="bill_address_line2">
            <mat-label>Billing Address</mat-label>
            <input matInput type="text" formControlName="billing_address_line2" />
            <mat-error *ngIf="billing_address_line2.invalid"> Billing Address is <strong>required</strong> </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="phone_box">
            <mat-label>Phone</mat-label>
            <input matInput type="text" formControlName="phone" required />
            <mat-error *ngIf="phone.invalid && (phone.dirty || phone.touched) && phone.errors.required">
              Phone Number is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="postcode_box">
            <mat-label>Post Code</mat-label>
            <input matInput type="text" formControlName="billing_postcode" required />
            <mat-error
              *ngIf="
                billing_postcode.invalid &&
                (billing_postcode.dirty || billing_postcode.touched) &&
                billing_postcode.errors.required
              "
            >
              Postcode is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <div class="card_input px-20 py-15 rounded-lg">
            <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"> </ngx-stripe-card>
          </div>
        </div>

        <br /><br />

        <mat-checkbox formControlName="tos_agreed"
          >I Agree to the
          <b
            ><a href="https://opsease.com/terms-of-use/" target="_blank" style="text-decoration: none"
              >OpsEase terms of use</a
            ></b
          >.
        </mat-checkbox>
        <mat-error *ngIf="tos_agreed.invalid && formSubmitted === true">
          Please agree to the <strong>terms of use.</strong>
        </mat-error>

        <br /><br /><br />
        <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"></re-captcha>
        <mat-error *ngIf="recaptchaReactive.invalid && formSubmitted === true">
          The checkbox must be checked.
        </mat-error>
        <br /><br />
        <div class="sign_up_button_container">
          <button id="registrationComplete" class="signup_button" type="submit" mat-raised-button color="primary">Create OpsEase Account</button>
        </div>
      </form>
    </mat-card>
  </section>
</div>
