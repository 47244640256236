<section class="section">
  <div class="section-header">
    <h1>Audit Logs</h1>
  </div>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let log">{{ log.message }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let log">{{ log.log.created_at | date: 'medium' }}</td>
    </ng-container>

    <!-- Expanded Content Column -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let log" [attr.colspan]="displayedColumns.length">
        <div class="log-detail" [@detailExpand]="log === expandedElement ? 'expanded' : 'collapsed'">
          <ul>
            <li class="mt-10"><strong>User:</strong> {{ log.log.user && log.log.user.name ? log.log.user.name : 'Anonymous'}}</li>
            <li class="mt-10"><strong>Request URL:</strong> {{ log.log.request_url }}</li>
            <li class="mt-10"><strong>IP Address:</strong> {{ log.log.ip }}</li>
            <li class="mt-10"><strong>User agent:</strong> {{ log.log.user_agent }}</li>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    >
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>

  <mat-paginator [length]="dataSource.count | async" [pageSize]="20" showFirstLastButtons></mat-paginator>
</section>
