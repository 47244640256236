import { Component, HostBinding } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../../auth/services/auth.service';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-user-invitation',
  templateUrl: './user-invitation.component.html',
  styleUrls: ['./user-invitation.component.scss'],
})
export class UserInvitationComponent {
  @HostBinding('class') class = 'user-invitation';
  public data: any = {};
  public apiError: string;

  public signUpForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
    ]),
    password: new UntypedFormControl('', [
      Validators.required
    ])
  });

  get name() {
    return this.signUpForm.get('name');
  }

  get password() {
    return this.signUpForm.get('password');
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    userService: UserService,
    route: ActivatedRoute,
  ) {
    if (userService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.data.token = route.snapshot.params.token;
  }

  submit() {
    this.data.name = this.name.value;
    this.data.password = this.password.value;

    this.authService
      .registerInvitedUser(this.data)
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/login']);
      }, (error) => {
        this.apiError = error.error.message;
      });
  }
}
