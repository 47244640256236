import { Component, Input, OnInit } from "@angular/core";
import { marked } from "marked";
@Component({
    selector: `app-render-markdown`,
    template: `
        <span [innerHTML] = "markdownText"></span>
    `
})
export class RenderMarkDownComponent implements OnInit {
    @Input() markdownText: string;
    constructor() { }

    async parsemarkdown() {
        this.markdownText = await marked.parse(this.markdownText);
    }
    ngOnInit(): void {
        this.parsemarkdown();
    }
}