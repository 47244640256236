import { NgModule } from '@angular/core';
import { TypingAnimationDirective } from './typing.animation.directive';
@NgModule({
    declarations: [
        TypingAnimationDirective
    ],
    exports: [
        TypingAnimationDirective
    ],
    imports: [
    ]
})
export class TypingAnimationModule { }